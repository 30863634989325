import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../../../assets/images/clientlogo/logofinal.png';
import './Footer.css';  // Custom CSS file

export const Footer = () => {
  return (
    <footer className="__custom-footer bg-dark text-white py-5">
      <Container>
        <Row>
          <Col lg={4} className="mt-4">
            <div>
              <img src={logo} alt="logo" height="50" />
              <p className="mt-3 fs-5">Empower your learning journey with SKICST Education</p>
              <p className='fs-3'>Near Kotwa Road Hanumanganj Prayagraj UP (221505)</p>
            </div>
          </Col>

          <Col lg={7} className="ms-lg-auto">
            <Row>
              <Col sm={4} xs={4} className="mt-4">
                <h5>Company</h5>
                <ul className="list-unstyled mt-3">
                  <li><a href="#about" className="__footer-link">About Us</a></li>
                  <li><a href="#plans" className="__footer-link">Plan</a></li>
                  <li><a href="#teams" className="__footer-link">Teams</a></li>
                </ul>
              </Col>
              <Col sm={4} xs={4} className="mt-4">
                <h5>Apps Pages</h5>
                <ul className="list-unstyled mt-3">
                  <li><a href="#" className="__footer-link">Calendar</a></li>
                  <li><a href="#" className="__footer-link">Mailbox</a></li>
                  <li><a href="#contact" className="__footer-link">Chat</a></li>
                </ul>
              </Col>
              <Col sm={4} xs={4} className="mt-4">
                <h5>Support</h5>
                <ul className="list-unstyled mt-3">
                  <li><a href="#" className="__footer-link">FAQ</a></li>
                  <li><a href="#contact" className="__footer-link">Contact</a></li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mt-3">
          <Col sm={6} xs={12} className="text-center text-sm-start text-xs-start">
            <p className="mb-0">© {new Date().getFullYear()} SAJAG. Crafted with <i className="mdi mdi-heart text-danger"></i> by <a href="https://lalkumarmitra.web.app/" className="__footer-link">Lal Mitra</a> | <a href="" className="__footer-link">Shubham</a></p>
          </Col>
          <Col sm={6} className="text-center text-sm-end">
            <ul className="list-inline mb-0">
              <li className="list-inline-item"><a href="#" className="__social-icon"><i className="ri-facebook-fill"></i></a></li>
              <li className="list-inline-item"><a href="#" className="__social-icon"><i className="ri-github-fill"></i></a></li>
              <li className="list-inline-item"><a href="#" className="__social-icon"><i className="ri-linkedin-fill"></i></a></li>
              <li className="list-inline-item"><a href="#" className="__social-icon"><i className="ri-google-fill"></i></a></li>
              <li className="list-inline-item"><a href="#" className="__social-icon"><i className="ri-dribbble-line"></i></a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
