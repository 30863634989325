import React, { useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './SingleAboutUs.css'; 
import { NavigationBar } from '../SectionNavigation/NavigationBar';
import { Footer } from '../SectionFooter/Footer';
import aboutlogo from '../../../../assets/images/about/imagelogo.png'

export const SingleAboutUs = () => {
    const [showMore, setShowMore] = useState(false);
    const handleToggle = () => {setShowMore(!showMore);};
    return (
        <>
            <NavigationBar />
            <Container fluid className="__about-us-section py-5">
                <Container>
                    {/* Header Section */}
                    <Row className="text-center mb-5 mt-4">
                        <Col>
                            <h1 className="__section-title">About Us</h1>
                            <p className="__section-subtitle">
                            Our institute was started on 29-july-2014 in the initial phase and public trust Act 21, 1860 (Reg.No: 1534/AL-35329).
                            </p>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                    {/* https://via.placeholder.com/500 */}
                        <Col md={6} className='mt-0'>
                            <Image
                                src={aboutlogo}
                                alt="Institute Image"
                                fluid
                                className="rounded shadow"
                               style={{height:'493px'}} 
                            />
                        </Col>
                        <Col md={6} className="__text-section">
                            <h2 className="__about-title text-info">About Insitute</h2>
                            <p className="__about-text">
                                SK Group of institution was started on 29-july-2014 in the initial phase as. SK INSTITUTE OF COMPUTER SCIENCE AND TECHNOLOGY. The Institute is run by the S.K memorial social Development society, which is regarded by the society and public trust Act 21, 1860 (Reg.No: 1534/AL-35329).
                                <br />
                                The organization was officiated to Rajiv Gandhi Computer Literacy (Shaksharta) mission. Kota Rajasthan in the year 2016 (Code: up-333) in which training is being given under computer courses DOAP, ADCA, DCA, DCAA, TALLY Etc. Conducted by RGCSM.
                                <br />
                               <hr />
                               In the year of 2016 Major organization conduct NIELIT by (National Institute of electronics & Information Technology) conducted by Indian Government. NIELIT is erstwhile (DOEACC). It affiliated by, (Code:8803771) in which conducted course is CCC and ‘O’ Level.
                               
                            </p>
                           
                            <Button variant="primary" className="__learn-more-btn" onClick={handleToggle}>{showMore ? 'Show Less' : 'Learn More'}</Button>
                            {showMore && (
                                <p className="showtext __about-text">
                                    <br />
                                    In the year 2016, under Pradhan Mantri Kaushal Vikas Yojana, training of students was done in MPKVY L.O. In 2018, under PMKVY 2.0, 320 students were trained under the job roles of retail and telecom sectors, and on 26-27 May, students’ campus selection was organized through the Kaushal Mela. In 2019, January, RPL training was done in the Kumbh special program of PMKVY 2.0. In 2020, the S.K Institute of One Day Exams started, preparing students for competitive exams.
                                </p>
                            )}
                        </Col>
                    </Row>


                    <Row className="mt-5 text-center">
                        <Col md={4} className="__highlight-box">
                            <h3 className="__highlight-title text-info">Experienced Faculty</h3>
                            <p className="__highlight-text">Our instructors are industry experts with years of experience.</p>
                        </Col>
                        <Col md={4} className="__highlight-box">
                            <h3 className="__highlight-title text-info">Modern Curriculum</h3>
                            <p className="__highlight-text">Stay up-to-date with the latest trends and technologies in the field.</p>
                        </Col>
                        <Col md={4} className="__highlight-box">
                            <h3 className="__highlight-title text-info">Career Opportunities</h3>
                            <p className="__highlight-text">We provide placement support and career guidance to all our students.</p>
                            <p className="__highlight-text">We provide placement support and career guidance to all our students.</p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Footer />
        </>
    );
};

