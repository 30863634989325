import React from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import img from '../../../../assets/images/about/aboutbackground3.png';
import './AboutUsData.css'; 

export const AboutUsData = () => {
    return (
        <div className="__about-us-section">
            <div className="__bg-overlay __bg-overlay-pattern"></div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div className="text-center mb-5">
                            <h3 className="mb-3 fw-semibold __about-us-title">About Us</h3>
                            <p className="text-muted mb-4 __about-us-description">
                                SK Group of institution was started on 29-July-2014 in the initial phase as SK INSTITUTE OF COMPUTER SCIENCE AND TECHNOLOGY.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="g-lg-5 g-4 align-items-center">
                    <Col lg="6">
                        <div className="__about-image" >
                            <img
                                src={img}
                                alt="About Us"
                                className="img-fluid mx-auto d-block rounded __transparent-background"
                            />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <Accordion defaultActiveKey="0" className="__about-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Director’s Message</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Dear Students, <br />
                                        The objectives of the S K Group of Institutions family are clear and the intentions are strong...
                                    </p>
                                    <p>
                                        “All my students are family member of this institute. Whom selection yet proved own success.”
                                    </p>
                                    <p className="fw-semibold text-end">
                                        - Mr. Girijesh Kumar Kesarwani <br />
                                        Director of SK Group of Institutions
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>About Institute</Accordion.Header>
                                <Accordion.Body>
                                    SK Group of institution was started on 29-july-2014 as SK INSTITUTE OF COMPUTER SCIENCE AND TECHNOLOGY. The Institute is run by the S.K memorial social development society...
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Vision</Accordion.Header>
                                <Accordion.Body>
                                    To provide good education to every student from rural areas, connecting them with modern technical education, and helping them build a better future.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Mission</Accordion.Header>
                                <Accordion.Body>
                                    Our mission is to offer high-quality education at low fees to ensure that everyone, including those unable to afford expensive courses, can access good education.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Value</Accordion.Header>
                                <Accordion.Body>
                                    Our main function is to provide technical education at nominal charges for both urban and rural areas across India, supporting the Indian government's computer revolution.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
