import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import './Room.css';  // Custom CSS file
import image from '../../../../assets/images/Room/background1.jpg';

// Room data array
const roomsData = [
  {
    id: 1,
    title: "Class Room",
    description: "Spacious classrooms with all modern facilities.",
    image: require('../../../../assets/images/Room/class3.jpeg'),
    effect: "fade-effect",
  },
  {
    id: 2,
    title: "Lab Room",
    description: "Advanced labs for practical learning and experimentation.",
    image: require('../../../../assets/images/Room/lab3.jpeg'),
    effect: "zoom-effect",
  },
  {
    id: 3,
    title: "Office Room",
    description: "Comfortable office spaces for administrative work.",
    image: require('../../../../assets/images/Room/lab2.jpeg'),
    effect: "slide-effect", 
  },
];

export const Room = () => {
  return (
    <div
      className="__room-carousel-section"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >


      <Container style={{ position: 'relative', zIndex: 2 }}> {/* Content in front of the overlay */}
        <h2 className="text-center text-dark fs-1 mb-4">Our Rooms</h2>
        <div>
          <Carousel className="__room-carousel" fade indicators={false}>
            {roomsData.map((room) => (
              <Carousel.Item key={room.id} className={room.effect}>
                <img className="d-block w-100" src={room.image} alt={room.title} />
                <Carousel.Caption>
                  <h3>{room.title}</h3>
                  <p>{room.description}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
};
