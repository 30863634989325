import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { authenticatedRoutes } from "../../../routes";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import img from '../../../helper/image_helper'
import { setPreloader } from "../../../features/Ui/uiSlice";
import { auth } from "../../../helper/api_url";
import { authenticate } from "../../../features/Auth/authSlice";
import Swal from "sweetalert2";
function LayoutSidebar() {
  const ref = useRef();
  const roleType = useSelector(state => state.auth._roleType);
  const dispatch = useDispatch()
  useEffect(() => {
    ref.current.recalculate();
  });
  const handleClickOnNavLink = () => {
    document.getElementsByTagName('body')[0].classList.remove('vertical-sidebar-enable');
  }
  const handleLogout = () =>{
    dispatch(setPreloader({loader:true,message:'Logging Out Please Wait ....'}))
    auth.logout()
   .then(()=>{
      localStorage.removeItem('_token');
      dispatch(authenticate({_token:null,_user:{}}))
      dispatch(setPreloader({loader:false,message:''}))
    })
    .catch(err=>{
      dispatch(setPreloader({loader:false,message:''}))
      Swal.fire({
        title: "error",
        text: err.response ? err.response.data.message : err.message,
        icon: "error",
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        showCloseButton: !0,
      });
    });
  }
  const generateSidebar = type => {
    return authenticatedRoutes.map((route, idx) => {
      // console.log(route.users.indexOf(roleType)>=0);
      return (route.users.indexOf(roleType) >= 0) &&
        route.type === type && (
          route.children.length
            ? (<li className="nav-item w-100" key={idx}>
              <a className="nav-link menu-link" href={`#drpdwnid-${idx}`} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls={`drpdwnid-${idx}`}>
                <i className={route.icon}></i>{" "}
                <span data-key={`t-${route.label}`}>{route.label}</span>
              </a>
              <div className="collapse menu-dropdown" id={`drpdwnid-${idx}`}>
                <ul className="nav nav-sm flex-column">
                  {route.children.map((child, id) =>
                    !(child.users && child.users.indexOf(roleType) < 0) ?
                      (<li className="nav-item w-100" key={id}>
                        <NavLink to={child.path} className="nav-link" onClick={handleClickOnNavLink} data-key={`t-${child.label}`}><span className="ps-2">{child.label}</span></NavLink>
                      </li>)
                      : null
                  )}
                </ul>
              </div>
            </li>)
            : (<li className="nav-item w-100" key={idx}>
              <NavLink to={route.path} className="nav-link menu-link" onClick={handleClickOnNavLink} role="button"><i className={route.icon}></i> <span data-key={`t-${route.label}`}>{route.label}</span></NavLink>
            </li>)
        )
    })
  }
  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm"><img src={img.logo2} alt="" height="22" /></span>
          <span className="logo-lg"><img src={img.logo} alt="" width="200px" height="50" /></span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm"><img src={img.logo2} alt="" height="22" /></span>
          <span className="logo-lg"><img src={img.logo} alt="" width="200px" height="40" /></span>
        </Link>
        <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <SimpleBar ref={ref} className='custom-scrollbar' id="scrollbar" style={{ maxHeight: "calc(100vh - 130px)" }}>
        <Container fluid>
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav" data-simplebar>
            <li className="menu-title w-100"><span data-key="t-menu">Menu</span>
            </li>


            {generateSidebar('menu')}
            <li className="menu-title w-100"><i className="ri-more-fill"></i><span data-key="t-pages">Modules</span></li>
            {generateSidebar('module')}
            <li className="menu-title w-100"><i className="ri-more-fill"></i><span data-key="t-components">Settings</span></li>
            {generateSidebar('setting')}
          </ul>
        </Container>
      </SimpleBar>
      <button onClick={handleLogout} className="dropdown-item d-block d-lg-none pt-5 px-5"><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>Logout</button>
      <div className="sidebar-background"></div>
    </div>
  );
}

export default LayoutSidebar;
