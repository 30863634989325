import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Card } from 'react-bootstrap';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const MapComponent = () => {
    const [location, setLocation] = useState(null);
    return (
        <div className="col">
            <Card>
                <Card.Body>
                    <LoadScript googleMapsApiKey=''> 
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={location ? location.coordinates : { lat: 0, lng: 0 }} 
                            zoom={15}
                        >
                            {location && (
                                <Marker 
                                    position={location.coordinates} 
                                    title={location.name} 
                                />
                            )}
                        </GoogleMap>
                    </LoadScript>
                </Card.Body>
            </Card>
        </div>
    );
};

export default MapComponent;
