import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setPreloader } from '../../../../features/Ui/uiSlice';
import { toast } from 'react-toastify';
import { swal } from '../../../../helper/swal';
import { student, userAddress } from '../../../../helper/api_url';

export const AddressDetail = ({ enquiryData, studentId,setActiveStep,editAdmission}) => {
    const dispatch = useDispatch();
    const handleSubmit = e => {
        dispatch(setPreloader({ loader: true, message: 'please wait...' }))
        e.preventDefault();
        const formData = new FormData(e.target);
        student.add_address(formData).then(res => {
            toast.success("Address Added",res.data.message);
            setActiveStep(4);
            document.getElementById('activeStep4').onclick=() => setActiveStep(4);
            e.target.reset();
        }).catch(err => swal.error(err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: '' })))
    }
    const [sameAddress, setSameAddress] = useState(false);
    const handleCheckboxChange = () => {
        setSameAddress(!sameAddress);
        if (!sameAddress) {
            document.getElementById('addr1_c').value = document.getElementById('addr1_p').value || '';
            document.getElementById('addr2_c').value = document.getElementById('addr2_p').value || '';
            document.getElementById('city_c').value = document.getElementById('city_p').value || '';
            document.getElementById('district_c').value = document.getElementById('district_p').value || '';
            document.getElementById('state_c').value = document.getElementById('state_p').value || '';
            document.getElementById('pin_c').value = document.getElementById('pin_p').value || '';
        } else {
            document.getElementById('addr1_c').value = '';
            document.getElementById('addr2_c').value = '';
            document.getElementById('city_c').value = '';
            document.getElementById('district_c').value = '';
            document.getElementById('state_c').value = '';
            document.getElementById('pin_c').value = '';
        }
    };
    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <input type="hidden" value={studentId} name='user_id' />
                <Row>
                    <div className="mb-4"><h5 className="mb-1 text-center text-primary">Permanent Address</h5></div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="addr1_p" className="form-label">Address 1</label>
                            <textarea name='addr1_p' id='addr1_p' className='form-control' defaultValue={enquiryData?.address_1 || editAdmission?.personal_details?.permanent_address?.addr1} cols={1} rows={1} />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="addr2_p" className="form-label">Address 2</label>
                            <textarea name='addr2_p' id='addr2_p' className='form-control' defaultValue={enquiryData?.address_2 || editAdmission?.personal_details?.permanent_address?.addr2} rows={1} />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="city_p" className="form-label">City</label>
                            <input type='text' name='city_p' id='city_p' defaultValue={enquiryData?.district || editAdmission?.personal_details?.permanent_address?.city} className='form-control' />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="district_p" className="form-label">District</label>
                            <input type='text' name='district_p' id='district_p' defaultValue={enquiryData?.district || editAdmission?.personal_details?.permanent_address?.district} className='form-control' />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="state_p" className="form-label">State</label>
                            <input type='text' name='state_p' id='state_p' defaultValue={enquiryData?.state || editAdmission?.personal_details?.permanent_address?.state} className='form-control' />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="pin_p" className="form-label">Pin</label>
                            <input type='number' name='pin_p' id='pin_p' defaultValue={enquiryData?.pin || editAdmission?.personal_details?.permanent_address?.pin} className='form-control' />
                        </div>
                    </div>
                </Row>
                <hr />
                <Row>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"  onChange={handleCheckboxChange}/>
                    <label classNamme="form-check-label" for="flexCheckDefault">
                        Same Address
                    </label>
                </div>
                    <div className="my-2"><h5 className="mb-1 text-center text-primary">Current Address</h5></div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="addr1_c" className="form-label">Address 1</label>
                            <textarea name='addr1_c' id='addr1_c' className='form-control' defaultValue={editAdmission?.personal_details?.current_address?.addr1} cols={1} rows={1} />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="addr2_c" className="form-label">Address 2</label>
                            <textarea name='addr2_c' id='addr2_c' defaultValue={editAdmission?.personal_details?.current_address?.addr2} className='form-control' rows={1} />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="city_c" className="form-label">City</label>
                            <input type='text' name='city_c' id='city_c' defaultValue={editAdmission?.personal_details?.current_address?.city} className='form-control' />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="district_c" className="form-label">District</label>
                            <input type='text' name='district_c' id='district_c' defaultValue={editAdmission?.personal_details?.current_address?.district} className='form-control' />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="state_c" className="form-label">State</label>
                            <input type='text' name='state_c' id='state_c' defaultValue={editAdmission?.personal_details?.current_address?.state} className='form-control' />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="pin_c" className="form-label">Pin</label>
                            <input type='number' name='pin_c' id='pin_c' defaultValue={editAdmission?.personal_details?.current_address?.pin} className='form-control' />
                        </div>
                    </div>

                    <div className="d-flex align-items-start gap-3 mt-4">
                    <button type="submit"  className={`btn btn-primary btn-label right ms-auto nexttab nexttab ${editAdmission?.personal_details?.permanent_address?.id?'disabled':''}`} ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{editAdmission?.personal_details?.permanent_address?.id?'Update':'Save'} & Next</button>
                    </div>
                </Row>
            </form>
        </>
    )
}
