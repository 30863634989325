import React, { useState } from 'react'
import { Row } from 'react-bootstrap';

export const EditAddress = ({admissionData}) => {

    const [sameAddress, setSameAddress] = useState(false);
    const handleCheckboxChange = () => {
        setSameAddress(!sameAddress);
        if (!sameAddress) {
            document.getElementById('addr1_c').value = document.getElementById('addr1_p').value || '';
            document.getElementById('addr2_c').value = document.getElementById('addr2_p').value || '';
            document.getElementById('city_c').value = document.getElementById('city_p').value || '';
            document.getElementById('district_c').value = document.getElementById('district_p').value || '';
            document.getElementById('state_c').value = document.getElementById('state_p').value || '';
            document.getElementById('pin_c').value = document.getElementById('pin_p').value || '';
        } else {
            document.getElementById('addr1_c').value = '';
            document.getElementById('addr2_c').value = '';
            document.getElementById('city_c').value = '';
            document.getElementById('district_c').value = '';
            document.getElementById('state_c').value = '';
            document.getElementById('pin_c').value = '';
        }
    };
  return (
    <>
    <form onSubmit={(e) => ''}>
        <input type="hidden" value={admissionData?.id} name='user_id' />
        <Row>
            <div className="mb-4"><h5 className="mb-1 text-center text-primary">Permanent Address</h5></div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="addr1_p" className="form-label">Address 1</label>
                    <textarea name='addr1_p' id='addr1_p' className='form-control' defaultValue={admissionData?.addresses[0]?.type==='permanent'?admissionData?.addresses[0]?.addr1:''} cols={1} rows={1} />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="addr2_p" className="form-label">Address 2</label>
                    <textarea name='addr2_p' id='addr2_p' className='form-control' defaultValue={admissionData?.addresses[0]?.type==='permanent'?admissionData?.addresses[0]?.addr2:''} rows={1} />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="city_p" className="form-label">City</label>
                    <input type='text' name='city_p' id='city_p' defaultValue={admissionData?.addresses[0]?.type==='permanent'?admissionData?.addresses[0]?.city:''} className='form-control' />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="district_p" className="form-label">District</label>
                    <input type='text' name='district_p' id='district_p' defaultValue={admissionData?.addresses[0]?.type==='permanent'?admissionData?.addresses[0]?.district:''} className='form-control' />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="state_p" className="form-label">State</label>
                    <input type='text' name='state_p' id='state_p' defaultValue={admissionData?.addresses[0]?.type==='permanent'?admissionData?.addresses[0]?.state:''} className='form-control' />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="pin_p" className="form-label">Pin</label>
                    <input type='number' name='pin_p' id='pin_p' defaultValue={admissionData?.addresses[0]?.type==='permanent'?admissionData?.addresses[0]?.pin:''} className='form-control' />
                </div>
            </div>
        </Row>
        <hr />
        <Row>
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"  onChange={handleCheckboxChange}/>
            <label classNamme="form-check-label" for="flexCheckDefault">
                Same Address
            </label>
        </div>
            <div className="my-2"><h5 className="mb-1 text-center text-primary">Current Address</h5></div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="addr1_c" className="form-label">Address 1</label>
                    <textarea name='addr1_c' id='addr1_c' className='form-control' defaultValue={admissionData?.addresses[1]?.type==='current'?admissionData?.addresses[1]?.addr1:''} cols={1} rows={1} />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="addr2_c" className="form-label">Address 2</label>
                    <textarea name='addr2_c' id='addr2_c' defaultValue={admissionData?.addresses[1]?.type==='current'?admissionData?.addresses[1]?.addr2:''} className='form-control' rows={1} />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="city_c" className="form-label">City</label>
                    <input type='text' name='city_c' id='city_c' defaultValue={admissionData?.addresses[1]?.type==='current'?admissionData?.addresses[1]?.city:''} className='form-control' />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="district_c" className="form-label">District</label>
                    <input type='text' name='district_c' id='district_c'defaultValue={admissionData?.addresses[1]?.type==='current'?admissionData?.addresses[1]?.district:''} className='form-control' />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="state_c" className="form-label">State</label>
                    <input type='text' name='state_c' id='state_c' defaultValue={admissionData?.addresses[1]?.type==='current'?admissionData?.addresses[1]?.state:''} className='form-control' />
                </div>
            </div>
            <div className="col-6 mb-2">
                <div>
                    <label htmlFor="pin_c" className="form-label">Pin</label>
                    <input type='number' name='pin_c' id='pin_c'defaultValue={admissionData?.addresses[1]?.type==='current'?admissionData?.addresses[1]?.pin:''} className='form-control' />
                </div>
            </div>

            <div className="d-flex align-items-start gap-3 mt-4">
            <button type="submit"  className={`btn btn-primary btn-label right ms-auto nexttab nexttab`} ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Update & Next</button>
            </div>
        </Row>
    </form>
</>
  )
}
