import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './ContactPage.css'; 


export const ContactUs = () => {
    // const [formData, setFormData] = useState({ name: '',email: '',message: '',});
    // const [showAlert, setShowAlert] = useState(false);
    // const handleChange = (e) => { const { name, value } = e.target;setFormData({...formData, [name]: value,});};
    // const handleSubmit = (e) => {e.preventDefault();console.log(formData);setShowAlert(true);};
    return (
        <div className="contact-us-section" style={{overflowX:'hidden'}}>
            {/* <Row>
                <h2 className="text-center text-info mb-4">Contact Us</h2>
                <Col lg={6}>
                    <div className="contact-image" >
                        <img src={img} alt="About Us" className="img-fluid mx-auto d-block rounded transparent-contact"/>
                    </div>
                </Col>
                <Col lg={4}>

                    {showAlert && (
                        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                            Thank you for your message! We will get back to you soon.
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit} className="contact-form shadow-lg p-4">
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" required />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Label>Contact</Form.Label>
                            <Form.Control type="number" name="number" value={formData.email} onChange={handleChange} placeholder="Enter your Mobile Number" required />
                        </Form.Group>
                        <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" required />
                        </Form.Group>

                        <Form.Group controlId="formMessage" className="mt-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={5} name="message" value={formData.message} onChange={handleChange} placeholder="Enter your message" />
                        </Form.Group>

                        <div className="text-center mt-4">
                            <Button variant="info" type="submit">
                                Send Message
                            </Button>
                        </div>
                    </Form>


                </Col>
            </Row> */}
             <Container fluid className="__contact-page-section py-5">
            <h2 className="text-center text-info mb-4">Contact Us</h2>
            <Container>
                <Row className='my-5'>
                    <Col md={7} className="__form-section">
                        <h2 className="__form-title text-center">Get In Touch With Our Institute</h2>
                        <Form>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formFirstName">
                                        <Form.Control type="text" placeholder="First Name*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formLastName">
                                        <Form.Control type="text" placeholder="Last Name*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formEmail">
                                        <Form.Control type="email" placeholder="Email*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formPhoneNumber">
                                        <Form.Control type="tel" placeholder="Phone Number*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="formMessage" className="mb-3">
                                <Form.Control as="textarea" rows={5} placeholder="Message" className='__form-control'required />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="__btn-submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col md={5} className="__contact-info-section text-center">
                        <h3>Get In Touch With Direct Institute</h3>
                        <p>When selecting a service for our computer institute, we should consider factors like their experience in the education field, expertise in technology, reputation, and alignment with our institute’s values and educational goals.</p>
                        <div className="__contact-details">
                            <p><i className="mdi mdi-microsoft-office text-dark"></i> Main Office</p>
                            <p><i className='bx bx-location-plus me-2 mt-1 text-dark'></i>Kotwa Road Hanumanganj Prayagraj UP (221505)</p>
                            <p><i className="bx bx-phone-call me-2 text-dark"></i> Call Today: 9453360943</p>
                            <p><i className="bx bxs-time me-2 text-dark"></i> Monday To Friday: 7AM - 5PM</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

        </div>
    );
};
