import React from 'react'
import { Row } from 'react-bootstrap'
import CustomSelect from '../../../../components/CustomSelect'
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../features/Ui/uiSlice';
import { student, userAddress } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { toast } from 'react-toastify';

export const OtherDetail = ({enquiryData,studentId,setActiveStep,editAdmission}) => {
    const dispatch=useDispatch();
    const handleSubmit = e => {
        dispatch(setPreloader({ loader: true, message: 'please wait...' }))
        e.preventDefault();
        const formData = new FormData(e.target);
        student.add_other_details(formData).then(res => {
          toast.success("Other Details Saved",res.data.message);
          setActiveStep(3);
          document.getElementById('activeStep3').onclick=() => setActiveStep(3);
          swal.success(res.data.message);
          e.target.reset();
        }).catch(err => swal.error(err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: '' })))
      }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <input type="hidden" value={studentId} name='user_id' />
                <Row>
                    <div className="mb-4"><h5 className="mb-1 text-center text-primary">Other Information</h5></div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="father_name" className="form-label">Father Name <span className="text-danger px-1">*</span></label>
                            <input type="text" className="form-control" id='father_name' name="father_name" defaultValue={enquiryData?.father_name ||editAdmission?.personal_details?.father_name} />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="mother_name" className="form-label">Mother Name <span className="text-danger px-1">*</span></label>
                            <input type="text" className="form-control" id='mother_name' name="mother_name" defaultValue={editAdmission?.personal_details?.mother_name} />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="guardian_name" className="form-label">Guardian Name</label>
                            <input type="text" className="form-control" id='guardian_name' defaultValue={enquiryData?.father_name ||editAdmission?.personal_details?.guardian_name } name="guardian_name" />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="occupation" className="form-label">Occupation </label>
                            <input type="text" className="form-control" id='occupation' name="occupation" defaultValue={editAdmission?.personal_details?.occupation} />
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="handicapped" className="form-label">Handicapped</label>
                            <select name="handicapped" id="handicapped" defaultValue={editAdmission?.personal_details?.handicapped} className="form-control">
                                <option value="0">NO</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="ex_serviceman" className="form-label">Ex-Serviceman</label>
                            <select name="ex_serviceman" id="ex_serviceman" defaultValue={editAdmission?.personal_details?.ex_serviceman} className="form-control">
                                <option value="0">NO</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="ews" className="form-label">Ews</label>
                            <select name="ews" id="ews"defaultValue={editAdmission?.personal_details?.ews} className="form-control">
                                <option value="0">NO</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <div>
                            <label htmlFor="category" className="form-label">Category <span className="text-danger px-1">*</span></label>
                            <CustomSelect name="category" defaultValue={editAdmission?.personal_details?.category} isSearchable options={[
                                { label: 'Genral', value: 'gen' },
                                { label: 'OBC', value: 'obc' },
                                { label: 'Backward Class', value: 'bc' },
                                { label: 'SC', value: 'sc' },
                                { label: 'ST', value: 'st' },
                                { label: 'other', value: 'other' },
                            ]} />
                        </div>
                    </div>
                    
                    <div className="d-flex align-items-start gap-3 mt-4">
                            <button type="submit"  className={`btn btn-primary btn-label right ms-auto nexttab nexttab ${editAdmission?.personal_details?.id?'disabled':''}`} ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{editAdmission?.personal_details?.id?'Update':'Save'} & Next</button>
                        </div>
                </Row>
            </form>
        </>
    )
}
