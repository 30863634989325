import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import CustomSelect from '../../../../components/CustomSelect';
import { batch, course, student } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../features/Ui/uiSlice';
import { TableResponsive } from '../../../../components/common/TableResponsive';
import { batchSetCode } from '../../../../helper/formatDate';
import { useNavigate } from 'react-router-dom';
import { paymentModes } from '../../../../helper/constants';

export const EnrollementDetail = ({ studentId,editAdmission, setActiveStep }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [currentCourseFees, setCurrentCourseFees] = useState([]);
    const initialFeeCalculations = {
        cal_installments: 1,
        cal_payment_type: null,
        cal_discount: 0,
        cal_payables: 0,
        cal_payment: 0,
        cal_dues: 0,
        cal_next_payment_date: null
    };
    
    useEffect(() => {
        course.list().then(r => setCourses(r.data.course)).catch(e => console.log(e));
        batch.list().then(r => setBatches(r.data.batches)).catch(e => console.log(e));
    }, []);
    
    const getOptionsByPType = (row) => {
        return row.ptypes.map((item) => ({
            label: item.payment_type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()),
            value: item.payment_type
        }));
    };

    const handlePaymentTypeChange = (row, e) => {
        const totalFee = Math.floor(parseFloat(row.amount) * parseInt(row.total_installments));
        const rowDiscount = row.ptypes.find(pt => pt.payment_type === e.value).discount;
        const changedValues = {
            cal_payment_type: e,
            cal_installments: e.value === 'one_time' ? 1 : (e.value === 'two_time' ? 2 : row.total_installments),
            cal_discount: rowDiscount,
            cal_payables: (totalFee - (totalFee * parseFloat(rowDiscount) / 100)).toFixed(2),
            cal_payment: 0,
            cal_dues: (totalFee - (totalFee * parseFloat(rowDiscount) / 100)),
            cal_next_payment_date: null
        };
        setCurrentCourseFees(state =>
            state.map(s => {
                if (s.id === row.id) {
                    return { ...s, ...changedValues };
                }
                return s;
            })
        );
    };

    const handlePaymentChange = (row, e) => {
        if (row.cal_payables) {
            const changedValues = {
                cal_payment: e.target.value,
                cal_dues: row.cal_payables - e.target.value,
            };
            setCurrentCourseFees(state =>
                state.map(s => {
                    if (s.id === row.id) {
                        return { ...s, ...changedValues };
                    }
                    return s;
                })
            );
        }
    };

    const handleNextPaymentDate = (row, e) => {
        const selectedDate = e.target.value;
    
        setCurrentCourseFees(state =>
            state.map(s => {
                if (s.id === row.id) {
                    return { ...s, cal_next_payment_date: selectedDate };
                }
                return s;
            })
        );
    };
    
    const handleEnrollmentSubmit = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: "Please wait ..." }));
        const formData = new FormData(e.target);
        const enrollData = {
            user_id: formData.get('user_id'),
            batch_id: formData.get('batch_id'),
            course_id: formData.get('course_id'),
            payment_mode: formData.get('payment_mode'),
            fees: currentCourseFees,
        };
        student.enrollement(enrollData)
            .then(res => {
                swal.success('Success', res.message);
                navigate('/admission-slip', { state: { id: res.data.student.id } });
            })
            .catch(e => swal.error('Error', e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: "" })));
    };
    
    const handleChangeInstallments = (row, e) => {    
        setCurrentCourseFees(state =>
            state.map(s => {
                if (s.id === row.id) {
                    return { ...s, cal_installments: e.target.value };
                }
                return s;
            })
        );
    };

    // Calculate total payment and total dues
    const totalPayment = useMemo(() => 
        currentCourseFees.reduce((acc, fee) => acc + parseFloat(fee.cal_payment || 0), 0)
    , [currentCourseFees]);

    const totalDues = useMemo(() => 
        currentCourseFees.reduce((acc, fee) => acc + parseFloat(fee.cal_dues || 0), 0)
    , [currentCourseFees]);

    const currentCourseFeeColumns = useMemo(() => [
        {
            Header: "Fee Name",
            accessor: "name",
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <div>
                        <span className="position-relative">{row.name}</span>
                        <span className="ms-2 badge badge-soft-warning d-inline-flex align-items-center">
                            <i className='ri-close-fill me-1'></i> {row.total_installments}
                        </span>
                    </div>
                );
            }
        },
        {
            Header: "Fee",
            Cell: cell => {
                const row = cell.row.original;
                return (<span>{Math.floor(row.amount * row.total_installments)}</span>);
            }
        },
        {
            Header: "Payment type",
            Cell: cell => {
                const row = cell.row.original;
                const options = getOptionsByPType(row);
                return (
                    <span>
                        <CustomSelect
                            key={row.id}
                            options={options}
                            value={row.cal_payment_type}
                            onChange={(e) => handlePaymentTypeChange(row, e)}
                        />
                    </span>
                );
            }
        },
        {
            Header: "Discount",
            Cell: cell => {
                const row = cell.row.original;
                return <span className={row.cal_discount > 0 ? 'text-success' : ''}>{row.cal_discount} %</span>;
            }
        },
        {
            Header: "Payable",
            Cell: cell => {
                const row = cell.row.original;
                return <span>{row.cal_payables}</span>;
            }
        },
        {
            Header: "Payment",
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <input 
                        key={row.id} 
                        value={row.cal_payment}
                        disabled={!row.cal_payables} 
                        onChange={e => handlePaymentChange(row, e)} 
                        className='form-control' type='number' step={0.05} 
                    />
                );
            }
        },
        {
            Header: "Installments",
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <input 
                        key={row.id} 
                        value={row.cal_installments}
                        disabled={!row.cal_payables} 
                        onChange={e => handleChangeInstallments(row, e)} 
                        className='form-control' type='number' step={0.05} 
                    />
                );
            }
        },
        {
            Header: "Dues",
            Cell: cell => {
                const row = cell.row.original;
                return <span className={row.cal_dues > 0 ? 'text-danger':'text-success'}>{row.cal_dues?.toFixed(2)}</span>;
            }
        },
        {
            Header: "Next Payment Date",
            Cell: cell => {
                const row = cell.row.original;
                return (<input className='form-control' value={row.cal_next_payment_date || ''} type='date' onChange={(e) => handleNextPaymentDate(row, e)} />);
            }
        },
    ], []);

    return (
        <>
            <form onSubmit={handleEnrollmentSubmit}>
                <input type='hidden' value={studentId} name='user_id' />
                {/* <input type='hidden' value={108} name='user_id' /> */}
                <Row>
                    <div className="mb-4"><h5 className="mb-1 text-center text-primary">Enrollement</h5></div>
                    <div className="col-4">
                        <div>
                            <label htmlFor="course_id" className="form-label">Select Course</label>
                            <CustomSelect
                                name="course_id"
                                id="course_id"
                                options={courses.map(c => ({ label: c.name, value: c.id }))}
                                onChange={e => setCurrentCourseFees(courses.filter(c => c.id == e.value)[0].fees?.map(f => ({ ...f, ...initialFeeCalculations })))}
                            />
                        </div>
                    </div>
                    <div className="col-4 mb-">
                        <div>
                            <label htmlFor="batch_id" className="form-label">Batch</label>
                            <CustomSelect name="batch_id" id="batch_id" options={batches.map(b => ({ label: batchSetCode(b), value: b.id }))} />
                        </div>
                    </div>
                    <div className="col-4 mb-">
                        <div>
                            <label htmlFor="payment_mode" className="form-label">Fee Payment Mode</label>
                            <CustomSelect name="payment_mode" defaultValue={paymentModes.find(m => m.value == 'upi')} id="payment_mode" options={paymentModes} />
                        </div>
                    </div>
                    <TableResponsive
                        isLoading={false}
                        showFilter={false}
                        isPagination={false}
                        isShowingPageLength={false}
                        showCustomOptionPage={false}
                        columns={currentCourseFeeColumns}
                        data={currentCourseFees}
                        noRecord='Selected Course Does Not have Fees' />
                </Row>
                <div className="row mt-3">
                    <div className="col-6">
                        <h6>Total Payment: <span className="text-success">{totalPayment.toFixed(2)}</span></h6>
                    </div>
                    <div className="col-6 text-end">
                        <h6>Total Dues: <span className={totalDues > 0 ? 'text-danger' : 'text-success'}>{totalDues.toFixed(2)}</span></h6>
                    </div>
                </div>
                <div className="d-flex align-items-start gap-3 mt-4">
                    <Button type="submit" className={`btn btn-primary btn-label right ms-auto nexttab nexttab ${editAdmission?.id?'disabled':''}`}>
                        <i className="bx bx-chevrons-right label-icon align-middle fs-16 ms-2"></i>{editAdmission?.id?'update':''} Enroll
                    </Button>
                </div>
            </form>
        </>
    );
};
