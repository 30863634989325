import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './ContactPage.css'; 
import { NavigationBar } from '../SectionNavigation/NavigationBar';
import { Footer } from '../SectionFooter/Footer';

export const ContactPage = () => {
    return (
        <>
        <NavigationBar />
        <Container fluid className="__contact-page-section py-5">
            <Container>
                <Row className='my-5'>
                    <Col md={7} className="__form-section">
                        <h2 className="__form-title text-center">Get In Touch With Our Institute</h2>
                        <Form>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formFirstName">
                                        <Form.Control type="text" placeholder="First Name*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formLastName">
                                        <Form.Control type="text" placeholder="Last Name*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formEmail">
                                        <Form.Control type="email" placeholder="Email*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="formPhoneNumber">
                                        <Form.Control type="tel" placeholder="Phone Number*" className='__form-control' required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="formMessage" className="mb-3">
                                <Form.Control as="textarea" rows={5} placeholder="Message" className='__form-control' required />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="__btn-submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col md={5} className="__contact-info-section text-center">
                        <h3>Get In Touch With Direct Institute</h3>
                        <p>When selecting a service for our computer institute, we should consider factors like their experience in the education field, expertise in technology, reputation, and alignment with our institute’s values and educational goals.</p>
                        <div className="__contact-details">
                            <p><i className="mdi mdi-microsoft-office text-dark"></i> Main Office</p>
                            <p><i className='bx bx-location-plus me-2 mt-1 text-dark'></i>Kotwa Road Hanumanganj Prayagraj UP (221505)</p>
                            <p><i className="bx bx-phone-call me-2 text-dark"></i> Call Today: 9453360943</p>
                            <p><i className="bx bxs-time me-2 text-dark"></i> Monday To Friday: 7AM - 5PM</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

        <Footer />
        </>
    );
};
