import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Achievements.css'; // Custom CSS file for styling

const achievementsData = [
  {
    id: 1,
    icon: "mdi mdi-account-multiple-check-outline",
    title: "Students Enrolled",
    value: 3600,
  },
  {
    id: 2,
    icon: "mdi mdi-book-open-page-variant",
    title: "Courses ",
    value: 12,
  },
  {
    id: 3,
    icon: "bx bxs-user-plus",
    title: "Teachers",
    value: 10,
  },
  {
    id: 4,
    icon: " bx bx-street-view",
    title: "Total Visitor",
    value: 6340,
  },
];

export const Achievements = () => {
  const [counters, setCounters] = useState([0, 0, 0, 0]);
  const [hasStarted, setHasStarted] = useState(false);
  const sectionRef = useRef(null); 

  const startCounter = () => {
    const interval = setInterval(() => {
      setCounters((prevCounters) => {
        return prevCounters.map((count, i) => {
          if (count < achievementsData[i].value) {
            return count + Math.ceil(achievementsData[i].value / 100);
          }
          return count;
        });
      });
    }, 30);

    setTimeout(() => {
      clearInterval(interval);
    }, 3000);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!hasStarted) {
        const sectionPosition = sectionRef.current.getBoundingClientRect().top; 
        const windowHeight = window.innerHeight;

        if (sectionPosition <= windowHeight * 0.75) { 
          setHasStarted(true);
          startCounter();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasStarted]);

  return (
    <div className="__achievements-section" ref={sectionRef}>
      <Container>
        <h2 className="__section-title text-light text-center">Our Achievements</h2>
        <Row>
          {achievementsData.map((achievement, index) => (
            <Col key={achievement.id} md={3} sm={6} xs={6} className="mb-4">
              <div className="__achievement-card text-center">
                <div>
                    <i className={`${achievement.icon} __achievement-icon`}></i>
                  <Card.Title className="__achievement-title text-light">{achievement.title}</Card.Title>
                  <Card.Text className="__achievement-value">
                    {counters[index]} {achievement.title !== "Years of Service" && "+"}
                  </Card.Text>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

