import React from 'react';
import { Pagination, Navigation, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './OurCourse.css'; 

const courses = [
  { id: 1, title: 'ADCA', description: 'Advanced Diploma in Computer Applications' },
  { id: 2, title: 'DOAP', description: 'Diploma in Office Automation & Publishing' },
  { id: 3, title: 'O Level', description: 'O Level Computer Course' },
  { id: 4, title: 'PGDCA', description: 'Post Graduate Diploma in Computer Applications' },
  { id: 5, title: 'DCA', description: 'Diploma in Computer Applications' },
  { id: 6, title: 'DCAA', description: 'Diploma in Computer Accounting Applications' },
  { id: 7, title: 'CCC', description: 'Course on Computer Concepts' },
  { id: 8, title: 'BCA', description: 'Bachelor of Computer Applications' },
  { id: 9, title: 'MCA', description: 'Master of Computer Applications' },
];

export const OurCourse = () => {
  return (
    <div className="__course-cards-section">
      <h2 className="text-center mb-4">Our Courses</h2>
      <Swiper
        modules={[Pagination, Navigation, Scrollbar, A11y,Autoplay]}
        spaceBetween={10}
        slidesPerView={3}
        navigation
        loop={true}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          640: { slidesPerView: 1 },
          547: { slidesPerView: 1 },
          768: { slidesPerView: 1 },
          1024: { slidesPerView: 3 },
        }}
      >
        {courses.map((course) => (
          <SwiperSlide key={course.id}>
            <div className="__course-card h-100 shadow-lg rounded text-center p-4">
              <h3 className="__course-title text-info">{course.title}</h3>
              <p className="__course-description">{course.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
