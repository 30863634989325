import React from 'react'
import { NavigationBar } from './SectionNavigation/NavigationBar'
import EnhancedCarousel from './SectionCarousel/EnhancedCarousel'
import { TrustLogo } from './TrustLogo/TrustLogo'
import { OurTeam } from './SectionOurTeam/OurTeam'
import { Achievements } from './SectionAchivement/Achievements'
import { Room } from './SectionClass/Room'
import { OurCourse } from './SectionCourse/OurCourse'
import { ContactUs } from './SectionContactUs/ContactUs'
import { WorkProcess } from '../../../components/LandingPage/WorkProcess'
import { AboutUsData } from './AboutUsData/AboutUsData'
import { Footer } from './SectionFooter/Footer'
import MapComponent from './SectionMap/MapComponent'

export const StartPage = () => {
    return (
        <div>
            <NavigationBar />
            <EnhancedCarousel />
            <AboutUsData />
            <OurTeam />
            <TrustLogo />
            <Achievements />
            <Room />
            <OurCourse />
            <ContactUs />
            <WorkProcess />
           <MapComponent />
            <Footer />
        </div>
    )
}
