import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import './EnhancedCarousel.css'; 
import image1 from '../../../../assets/images/landingCrousel/img1.jpg';
import image2 from '../../../../assets/images/landingCrousel/img2.jpg';
import image3 from '../../../../assets/images/landingCrousel/img3.jpg';
import { Link } from 'react-router-dom';

const EnhancedCarousel = () => {
  const splitTextToSpans = (text) => {
    return text.split("").map((char, index) => {
      if (char === " ") {
        return <span key={index}>&nbsp;</span>; 
      } else {
        return (
          <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
            {char}
          </span>
        );
      }
    });
  };

  const slides = [
    {
      image: image1,
      title: "Welcome to SKICST",
      subtitle: "Best Computer Education",
      buttonText: "Contact Us",
      effect: "fade-effect", 
    },
    {
      image: image2,
      title: "Examination Student's",
      subtitle: "Final Exam",
      buttonText: "Contact Us",
      effect: "zoom-effect",
    },
    {
      image: image3,
      title: "Leadership Excellence Awards 2022",
      subtitle: "Success Begins Here",
      buttonText: "Contact Us",
      effect: "slide-effect", 
    },
  ];

  return (
    <div style={{ backgroundImage: `url(${image3})`, backgroundSize: 'cover', backgroundPosition: 'center',overflowX:'hidden' }}>
      <Carousel className="__custom-carousel" fade controls={true} >
        {slides.map((slide, index) => (
          <Carousel.Item interval={4000} key={index} className={slide.effect}>
            <img className="d-block w-100 __carousel-img" src={slide.image} alt={`Slide ${index + 1}`} />
            <div className="__carousel-overlay"></div> 
            <Carousel.Caption className="__carousel-caption-custom">
              <h1 className="__carousel-title text-black">
                {splitTextToSpans(slide.title)}
              </h1>
              <p className="__carousel-subtitle">{slide.subtitle}</p>
              <Link to={'/contactus'} variant="info" className="__carousel-btn btn btn-info">
                <i className="bx bxs-phone-call me-2"></i>{slide.buttonText}
              </Link>
              <Button variant="danger" className="__carousel-btn mx-3">
                <i className="mdi mdi-headset"></i> Enquiry
              </Button>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default EnhancedCarousel;
