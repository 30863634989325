import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import './OurTeam.css';
import img1 from '../../../../assets/images/Teams/girijesh.jpeg';
import img2 from '../../../../assets/images/Teams/shubham.jpeg';
import img3 from '../../../../assets/images/Teams/ramkumar.jpg';
import img4 from '../../../../assets/images/Teams/mahek.jpg';
import img5 from '../../../../assets/images/Teams/shivani.jpg';
import img6 from '../../../../assets/images/Teams/khushi.jpg';
import aboutbg from '../../../../assets/images/about/about-bg.jpg';

const teamMembers = [
    {
        id: 1,
        name: "Girijesh Kersari",
        role: "Director",
        img: img1,
    },
    {
        id: 2,
        name: "Shubham Maurya",
        role: "Software Developer & Lab Assistant",
        img: img2,
    },
    {
        id: 3,
        name: "Ram Kumar",
        role: "Office Assistant",
        img: img3,
    },
    {
        id: 4,
        name: "Mahek Kersarwani",
        role: "Reception",
        img: img4,
    },
    {
        id: 5,
        name: "Shivani Dwivedi",
        role: "Reception",
        img: img5,
    },
    {
        id: 6,
        name: "Khushi Kesarwani",
        role: "Tally Assistant",
        img: img6,
    },
];

export const OurTeam = () => {
    const [cardsPerSlide, setCardsPerSlide] = useState(3);
    const updateCardsPerSlide = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 500) {
            setCardsPerSlide(1);
        } else if (screenWidth <= 768) {
            setCardsPerSlide(2);
        } else {
            setCardsPerSlide(3);
        }
    };

    useEffect(() => {
        updateCardsPerSlide();
        window.addEventListener('resize', updateCardsPerSlide);
        return () => {
            window.removeEventListener('resize', updateCardsPerSlide);
        };
    }, []);
    
    const slides = [];
    for (let i = 0; i < teamMembers.length; i += cardsPerSlide) {
        slides.push(teamMembers.slice(i, i + cardsPerSlide));
    }

    return (
        <div style={{ backgroundImage: `url(${aboutbg})`, padding: '50px 0' }}>
            <Container className="__team-section">
                <h2 className="__section-title text-light text-center">Our Staff</h2>
                <Carousel controls={true} indicators={false}>
                    {slides.map((slide, index) => (
                        <Carousel.Item key={index}>
                            <Row className="justify-content-center">
                                {slide.map((member) => (
                                    <Col key={member.id} md={4} sm={6} xs={12} className="d-flex justify-content-center">
                                        <Card className="__team-card" style={{backgroundColor:'rgb(247, 247, 247)'}}>
                                            <Card.Img variant="top" src={member.img} className="__team-card-img" />
                                            <Card.Body className='__card-body'>
                                                <Card.Title className='__card-title'>{member.name}</Card.Title>
                                                <Card.Text className='__card-text'>{member.role}</Card.Text>
                                                <Button variant="info" outline>Contact</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </div>
    );
};
