import React, { useMemo, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { TableResponsive } from '../../../../components/common/TableResponsive';
import ImageViewer from '../../../../components/ImageViewer';
import { ASSET_URL } from '../../../../helper/api_url';
import image from '../../../../assets/images/about.jpg'
import CustomSelect from '../../../../components/CustomSelect';
export const EditQualification = ({ documents, qualifications }) => {
    const [activeStep, setActiveStep] = useState();
    const qualificationListColumns = useMemo(() => [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Board / University', accessor: 'board_university' },
        { Header: 'Marks %', accessor: 'marks' },
        { Header: 'Passing Year', accessor: 'year' },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <div className="">
                        <Button className='btn btn-sm btn-soft-info me-1' onClick={e => ''} ><i className="ri-pencil-fill" /></Button>
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">

                            <h5 className="fs-13 mb-1">
                                <a href="#">{row.name}</a>
                            </h5>
                            <p className="text-muted mb-0">{row.document?.name} </p>
                        </div>
                        <div className="flex-grow-1">
                            {row.marks} % | {row.year}
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <Button className='btn btn-sm btn-soft-info me-1' onClick={e => ''} ><i className="ri-pencil-fill" /></Button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    ]);
    return (
        <>
            <Card>
                <div className="mb-1"><h5 className="mb-1 text-center text-primary">Qualifications</h5></div>
                <Row>
                    {
                        <TableResponsive
                            columns={qualificationListColumns}
                            data={qualifications}
                            isShowingPageLength={false}
                            showFilter={false}
                            showCustomOptionPage={false}
                            isPagination={false}
                            noRecord='No Documents Found' />
                    }
                </Row>
                <Card.Header><div className="mb-1"><h5 className="mb-1 text-center text-primary">Document</h5></div></Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <div className='d-flex align-items-center justify-content-start'>
                                {
                                    documents?.map((item, idx) => (
                                        <div key={idx} className='mx-auto'>
                                            <ImageViewer image={ASSET_URL + item.path}>
                                                <img
                                                    className=" rounded-circle mx-auto d-block"
                                                    style={{ objectFit: 'cover' }}
                                                    src={ASSET_URL + item.path}
                                                    width={"80px"}
                                                    height={"80px"}
                                                    alt="Document Image" />
                                            </ImageViewer>
                                            <p className='text-center text-success'>{item.name}
                                                <Button className='btn btn-sm btn-soft-success mx-1' onClick={e => ''} ><i className="ri-pencil-fill" /></Button>
                                            </p>
                                        </div>
                                    ))
                                }

                            </div>
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Footer className='text-end'>
                    <button
                        type="button"
                        className="btn btn-primary btn-label right ms-auto nexttab nexttab"
                        onClick={() => { setActiveStep(5) }}>
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save & Next
                    </button>
                </Card.Footer>

            </Card>

            {/* <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>Add Qualification</Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => ''}>
                        <Row>

                            <div className="col-6 mb-2">
                                <input type="hidden" name="user_id" id='user_id' defaultValue={studentId} />
                                <div>
                                    <label htmlFor="name" className="form-label">Highest Qualification</label>
                                    <CustomSelect name="name" id="name" options={[
                                        { value: 'matriculation', label: 'matriculation' },
                                        { value: 'intermediate', label: 'intermediate' },
                                        { value: 'graduation', label: 'graduation' },
                                        { value: 'masters', label: 'masters' },
                                        { value: 'none', label: 'none' },
                                    ]} />
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div>
                                    <label htmlFor="board_university" className="form-label">Board Name</label>
                                    <input type='text' name='board_university' id='board_university' defaultValue={editDocument?.board_university} className='form-control' />

                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div>
                                    <label htmlFor="year" className="form-label">Passing Year</label>
                                    <CustomSelect isSearchable options={year()} name='year' id='year' />
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div>
                                    <label htmlFor="marks" className="form-label">Marks in %</label>
                                    <input type='number' name='marks' id='marks' className='form-control' />
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div>
                                    <label htmlFor="doc_name" className="form-label">Document Name</label>
                                    <input type='text' name='doc_name' id='doc_name' defaultValue={editDocument?.name} className='form-control' />
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div>
                                    <label htmlFor="doc" className="form-label">Upload</label>
                                    <input type='file' name='doc' id='doc' className='form-control' />
                                </div>
                            </div>
                            <div className="d-flex align-items-start gap-3 mt-4">
                                <button type="submit" data-nexttab="pills-other-info-tab" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save</button>
                            </div>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal> */}
        </>
    )
}
