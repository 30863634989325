import React, { useState, useEffect } from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../../../assets/images/clientlogo/logofinal.png';
import './NavigationBar.css'; // Import the CSS file for custom styles
import { Link } from 'react-router-dom';

export const NavigationBar = () => {
    const [navbar, setNavbar] = useState(false);
    const [showTopBar, setShowTopBar] = useState(true);
    const changeBackground = () => {
        const scrollY = window.scrollY;
        const threshold = 80;
        if (scrollY >= threshold) {
            setNavbar(true);
            if (showTopBar) {
                setShowTopBar(true);
            }
        } else {
            setNavbar(false);
            if (!showTopBar) {
                setShowTopBar(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);

    return (
        <div style={{overflowX:'hidden'}}>
            {showTopBar && (
                <div className="bg-info __topbar-info py-2 mt-0 px-auto">
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='__head'>

                            <a href="mailto:girijeshkesari@gmail.com" className="text-light me-4">
                                <i className=" bx bx-envelope-open text-white me-2"></i>
                                girijeshkesari@gmail.com
                            </a>
                            <a href="tel:+919453360943" className="text-light __blinking">
                                <i className="bx bxs-phone-call text-white me-2"></i>
                                +91 9453360943
                            </a>
                            <a href="tel:+919453360943" className="px-2 text-light __contactNewLine">
                                <i className="bx bxs-phone-call text-white me-2"></i>
                                +91 7388493625
                            </a>
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <marquee className="fs-5 text-light">Welcome to S.K. Institute of Computer Science & Technology
                               | Kotwa Road Hanumanganj Prayagraj <span className='ps-3 information information blinking'>
                                    New Batch Start Please Contact US
                                </span>
                            </marquee>
                        </div>
                    </div>
                </div>

            )}
            <Navbar
                expand="lg"
                variant="dark"
                className={`__custom-navbar ${navbar ? '__navbar-scroll' : 'mt-5'}`}
                fixed="top"
            >
                <Container>
                    <Navbar.Brand href="#">
                        <img src={logo} alt="Logo" className="__logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link as={Link} to={'/'}   className="__nav-link-custom">Home</Nav.Link>
                            <Nav.Link as={Link} to={'/aboutus'} className="__nav-link-custom">About</Nav.Link>
                            <Nav.Link as={Link} to={'/#'} className="__nav-link-custom">Courses</Nav.Link>
                            <Nav.Link as={Link} to={'/#'} className="__nav-link-custom">Gallery</Nav.Link>
                            <Nav.Link as={Link} to={'/contactus'} className="__nav-link-custom">Contact</Nav.Link>
                           <div className='__loginBtn'> <Link to="/login" className="mx-auto btn btn-info ">Login <i className='mdi mdi-login px-1'></i></Link></div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};
